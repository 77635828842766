aio-shell.page-home {
  aio-doc-viewer {
    display: block;
    width: 100vw;
    min-height: 100vh;
    background-color: #412945;
    color: white;
  }

  .mat-drawer-content {
    overflow-x: hidden;
    overflow-y: auto;
  }

  header {
    background-image: url('/assets/images/header.svg');
    background-size: cover;
    background-position: center center;
    height: 80vh;
    max-height: 800px;
    color: #4b334d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 60px;
  }

  header img {
    width: auto;
    height: 240px;
    max-height: 30vh;
  }

  div.title {
    font-size: 60px;
    margin-top: 20px;
    text-transform: uppercase;

    @media only screen and (max-width: 320px) {
      font-size: 55px;
    }
  }

  div.subtitle {
    font-size: 30px;
    margin-top: 20px;

    @media only screen and (max-width: 320px) {
      font-size: 25px;
    }
  }

  header h2 {
    font-size: 20px;
  }

  header .cta {
    position: absolute;
    bottom: -22px;
  }

  button.cta,
  a.cta {
    font-size: 18px;
    padding: 6px 28px;
    color: white;
    text-transform: uppercase;
  }

  h2,
  p {
    font-family: 'Open Sans', sans-serif;
    max-width: 100%;
  }

  h1,
  h3 {
    font-family: 'PT Sans', sans-serif;
    text-transform: uppercase;
  }

  .ngrx-callout {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 60px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-column-gap: 0 20px;
    justify-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &:last-of-type {
      border-bottom: none;
    }

    h3 {
      font-weight: bold;
    }

    @media only screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .ngrx-callout-description {
    flex-basis: 400px;
    flex-grow: 0;
    flex-shrink: 0;

    h3 {
      margin-bottom: 8px;
      font-size: 20px;
      color: rgba(255, 255, 255, 0.82);
    }

    p {
      color: rgba(255, 255, 255, 0.82);
    }

    @media only screen and (max-width: 600px) {
      flex-basis: initial;
    }
  }

  @media screen and (min-width: 820px) {
    .ngrx-callout:nth-of-type(2) {
      .ngrx-callout-figure {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        justify-self: start;
      }
      .ngrx-callout-description {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }
    }
  }

  @media screen and (max-width: 820px) {
    .ngrx-callout-description {
      padding: 0 20px 30px;
      max-width: 480px;
      text-align: center;
    }
  }

  .sponsors {
    width: 100vw;
    background-color: white;
    padding: 50px 20px;
    text-align: center;

    h3 {
      color: #412945;
      font-size: 24px;
      margin-bottom: 24px;
    }
  }

  .final-ngrx-callout {
    background-image: url('/assets/images/header.svg');
    background-size: cover;
    background-position: bottom center;
    height: 180px;
    color: #4b334d;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    h3 {
      font-size: 20px;
    }

    .content {
      max-width: 960px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .cta {
      flex-shrink: 0;
    }
  }
}
